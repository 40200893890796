<template>
  <div class="grid gap-0 grid-cols-3">
    <!-- Daysssss -->
    <div id="day" class="w-full relative">
      <select
        class="w-full px-1 py-2 border-0 border-b-2 border-solid border-dokBlue-ultra appearance-none bg-transparent"
        v-model="formatDate.DD"
      >
        <option v-for="(day, index) in days" :key="index" :value="day">
          {{ day }}
        </option>
      </select>
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
      >
        <svg
          class="fill-current h-4 w-4"
          xmlns="http://www.w3.org/bg-transparent m-0 p-0 font-semibold text-lg ml-2 border-0 rounded-cu font-semibold2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
          />
        </svg>
      </div>
    </div>

    <!-- Monthsssss -->
    <div id="month" class="w-full relative">
      <select
        class="w-full px-1 py-2 border-0 border-b-2 border-solid border-dokBlue-ultra appearance-none bg-transparent"
        v-model="formatDate.MM"
      >
        <option v-for="(month, index) in months" :key="index" :value="month.id">
          {{ month.name }}
        </option>
      </select>
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
      >
        <svg
          class="fill-current h-4 w-4"
          xmlns="http://www.w3.org/bg-transparent m-0 p-0 font-semibold text-lg ml-2 border-0 rounded-cu font-semibold2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
          />
        </svg>
      </div>
    </div>

    <!-- Yearsssss -->
    <div id="year" class="w-full relative">
      <select
        class="w-full px-1 py-2 border-0 border-b-2 border-solid border-dokBlue-ultra appearance-none bg-transparent"
        v-model="formatDate.YYYY"
      >
        <option v-for="(year, index) in years" :key="index" :value="year">
          {{ year }}
        </option>
      </select>
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
      >
        <svg
          class="fill-current h-4 w-4"
          xmlns="http://www.w3.org/bg-transparent m-0 p-0 font-semibold text-lg ml-2 border-0 rounded-cu font-semibold2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    date: {
      type: Object,
      required: true
    }
  },
  watch: {
    "formatDate.DD": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        let formatDay = moment(this.date).set("date", newVal);
        this.$emit("update:date", formatDay);
      }
    },
    "formatDate.MM": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        let formatDay = moment(this.date).set("month", parseInt(newVal) - 1);
        this.$emit("update:date", formatDay);
      }
    },
    "formatDate.YYYY": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        let formatDay = moment(this.date).set("year", newVal);
        this.$emit("update:date", formatDay);
      }
    }
  },
  data() {
    return {
      formatDate: {
        DD: null,
        MM: null,
        YYYY: null
      },
      months: moment.months().map(function(m, i) {
        return {
          id: moment()
            .month(m)
            .format("MM"),
          name: m
        };
      }),
      years: [],
      days: []
    };
  },
  created() {
    if (this.date.isValid()) {
      this.formatDate.DD = this.date.format("D");
      this.formatDate.MM = this.date.format("MM");
      this.formatDate.YYYY = this.date.format("YYYY");
    } else {
      let dateNow = moment();
      this.formatDate.DD = dateNow.format("D");
      this.formatDate.MM = dateNow.format("MM");
      this.formatDate.YYYY = dateNow.format("YYYY");
    }

    // Generated Years
    for (let indexYear = 0; indexYear < 5; indexYear++) {
      this.years.push(moment().year() + indexYear);
    }
  },
  beforeMount() {
    this.dayInMonths();
  },
  methods: {
    dayInMonths() {
      this.days = this.date
        .set("month", this.formatDate.MM)
        .set("year", this.formatDate.YYYY)
        .daysInMonth();
    }
  }
};
</script>

<style>
</style>